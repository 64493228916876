<template>
	<div>
		<Modal class="modal--organogram">
			<template #header>
				<div class="modal-section__card__header__breadcrumbs">
					<div class="modal-section__card__header__breadcrumbs__img">
						<img src="@/assets/imgs/flags/flag-southafrica-medium.png" alt="" />
					</div>
					<VHeading level="5" style="text-transform: capitalize">
						{{ title }}
					</VHeading>
				</div>
				<VHeading level="2">{{ organisation.name }}</VHeading>
			</template>
			<template #content>
				<div id="svgChart"></div>
			</template>
			<template @click.stop #footer>
				<Actions @onSaveViewClick="setActiveModal('DataVisualViewModal')" />
				<!-- ToDo view next organogram -->
				<!-- <a href="#" class="button button--link button__primary--mobile button__primary">
                   View next engagement window
               </a> -->
			</template>
		</Modal>

		<DataVisualViewModal
			ref="modal"
			:errors="errors"
			@close="setActiveModal"
			@onSubmit="submitModalForm"
			v-if="modal"
		/>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { SAVE_VISUAL } from '../store/actions.type';

import OrganizationChart from 'vue-organization-chart';
import 'vue-organization-chart/dist/orgchart.css';

import '@/d3.min.js';
import Errors from '@/helpers/Errors';
import organogram from '@/mixins/organogram.js';

import DataVisualViewModal from '@/modals/DataVisualViewModal';

export default {
	name: 'OrganogramModal',
	components: {
		OrganizationChart,
		DataVisualViewModal
	},
	mixins: [organogram],
	data() {
		return {
			modal: null,
			errors: new Errors(),
			chart: {
				name: 'Ian Devling',
				imageUrl: 'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/cto.jpg',
				positionName: 'Cheaf Executive Officer ',
				children: [
					{
						name: ' Leverling Janet',
						imageUrl:
							'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg',
						positionName: 'CTO ',
						children: [
							{
								name: 'Carlos Diaz',
								imageUrl:
									'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
								positionName: 'Senior Android Developer'
							},
							{
								name: 'Sven Petersen',
								imageUrl:
									'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
								positionName: 'Senior Developer',
								children: [
									{
										name: 'Current User',
										imageUrl:
											'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png'
									},
									{
										name: 'Beate Vileid',
										imageUrl:
											'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg'
									}
								]
							}
						]
					},
					{
						name: 'Davolio Nancy',
						imageUrl:
							'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
						positionName: 'CTO ',
						children: [
							{
								name: 'Fuller Andrew',
								imageUrl:
									'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
								positionName: 'Linear Manager'
							},
							{
								name: 'Suyama Michael',
								imageUrl:
									'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
								positionName: 'Senior sales manager'
							},
							{
								name: 'King Robert',
								imageUrl:
									'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
								positionName: 'Senior Sales Manager',
								children: [
									{
										name: 'Callahan Laura',
										imageUrl:
											'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
										positionName: 'Junior sales manager',
										children: [
											{
												name: ' Leverling Janet',
												imageUrl:
													'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg',
												positionName: 'CTO '
											},
											{
												name: 'Fuller Andrew',
												imageUrl:
													'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
												positionName: 'Linear Manager'
											}
										]
									},
									{
										name: 'Dodsworth Anne',
										imageUrl:
											'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
										positionName: 'Junior sales manager'
									}
								]
							},
							{
								name: 'Yoshi Nagase',
								imageUrl:
									'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
								positionName: 'Head of laboratory',
								children: [
									{
										name: 'Valle Saavedra',
										imageUrl:
											'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
										positionName: 'Junior Inovator'
									},
									{
										name: 'Regina Murphy',
										imageUrl:
											'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
										positionName: 'Developer'
									},
									{
										name: 'Mayumi Ohno',
										imageUrl:
											'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
										positionName: 'Senior developer'
									},
									{
										name: 'SizePalette',
										imageUrl:
											'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
										positionName: 'System analyst'
									}
								]
							}
						]
					},
					{
						name: 'Beate Vileid',
						imageUrl:
							'https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg',
						positionName: 'Junior Developer'
					}
				]
			}
		};
	},
	computed: {
		...mapState({
			ewgFilters: state => state.ewg.ewgFilters,
			organisation: state => state.systemAndPolices.activeOrganisation
		}),
		title() {
			return `${this.ewgFilters.country.name} / ${this.organisation.type} / Organogram`;
		}
	},
	mounted() {
		this.loadChart();
	},
	methods: {
		setActiveModal(modal = null) {
			this.modal = modal;

			if (!modal) return;

			this.$nextTick(() => {
				this.$refs.modal.form.title = this.title;
				this.$refs.modal.title = this.title;
			});
        },
        // ToDo @steen - when organogram becomes active add logic for save visual
		async submitModalForm(form) {
			try {
				await this.$store.dispatch(SAVE_VISUAL, form);

				this.setActiveModal();
				this.$toastr.s('Success', 'Successfully saved visual');
			} catch (errors) {
				this.errors.record(errors.response.data.errors);
			}
		}
	}
};
</script>

<style scoped>
/deep/ .modal-section__card__content {
	overflow: hidden;
	max-height: 450px;
}
</style>
